import React from 'react'
import { Link } from 'react-router-dom'

import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import GalleryCard1 from '../components/gallery-card1'
import './home.css'

const Home = (props) => {

  const getCurrentYear = () => {
    return new Date().getFullYear().toString();
  }

  return (
    <div className="home-container">
      <Helmet>
        <title>Richmond Live</title>
        <meta property="og:title" content="Richmond Live" />
      </Helmet>
      <section className="home-hero">
        <div className="home-background"></div>
        <header data-thq="thq-navbar" className="home-navbar">
          <Link to="/" className="home-navlink">
            <img
              alt="image"
              src="/playground_assets/rl-logo-200h.png"
              className="home-image"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="home-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="home-nav"
            >
              <a href="#about-section" className="home-link navLink">
                About Us
              </a>
              <a
                href="https://loversquarrel.lk"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link1 navLink"
              >
                Lovers Quarrel
              </a>
              <a
                href="https://loversquarrel.lk"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link2 navLink"
              >
                Sponsors
              </a>
            </nav>
            <a
              href="https://www.youtube.com/@RichmondLive-RL/streams"
              target="_blank"
              rel="noreferrer noopener"
              className="home-register button"
            >
              <span>Watch Live</span>
              <svg viewBox="0 0 1024 1024" className="home-icon">
                <path d="M342 214l468 298-468 298v-596z"></path>
              </svg>
            </a>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu"></div>
        </header>
        <div className="home-hero-content">
          <h1 className="home-text01">
            <span className="home-text02">
              Sri Lanka&apos;s
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text03">Premium</span>
            <span className="home-text04"> School Broadcasting Team </span>
          </h1>
          <div className="home-caption">
            <button className="home-register1 button-style-1 button">
              <span className="home-text05">Text</span>
            </button>
            <p className="home-caption1">
              <span className="home-text06">Lovers&apos; Quarrel {getCurrentYear()}</span>
              <br className="home-text07"></br>
              <span className="home-text08">
                Catch the action live on loversquarrel.lk
              </span>
              <br></br>
            </p>
          </div>
        </div>
      </section>
      <section className="home-notes">
        <div id="about-section" className="home-first home-about">
          <div data-aos="fade-up-right" className="home-content">
            <h2 className="home-header">How we Think Beyond the Trend:</h2>
            <span className="home-text10">
              <span>
                Welcome to the webpage of Richmond Live, your premier source for
                live coverage of the Richmond Mahinda Lovers’ Quarrel!
              </span>
              <br className="home-text12"></br>
              <br></br>
              <span>
                Our team is made up of talented students from Richmond College
                who are passionate about webcasting and provide live coverage of
                this exciting event. We believe that the Richmond Mahinda
                Lovers’ Quarrel is one of the most thrilling events in the
                school calendar, and we are dedicated to bringing you all the
                action as it happens from the toss to the stumps.
              </span>
              <br className="home-text15"></br>
              <br></br>
              <span>
                At Richmond Live, we are committed to showcasing the best of the
                Richmond Mahinda Lovers Quarrel and providing a platform for the
                talented students who participate in this event. We believe that
                the event not only brings the school community together, but
                also fosters a spirit of friendly competition and sportsmanship.
              </span>
              <br className="home-text18"></br>
              <br></br>
              <span>
                Whether you&apos;re a student, a parent, or an alum of Richmond
                College, we invite you to tune in to our live coverage of the
                Richmond Mahinda Lovers Quarrel. Join us as we celebrate the
                talents and spirit of our students and cheer on our teams to
                victory! Cheer Richmond!
              </span>
              <br className="home-text21"></br>
              <br></br>
            </span>
            <div className="home-list">
              <div className="home-note">
                <div className="home-point"></div>
              </div>
              <div className="home-note1">
                <div className="home-point1"></div>
              </div>
            </div>
          </div>
          <img
            alt="image"
            src="https://i.imgur.com/IxikjDE.jpg"
            loading="eager"
            className="home-image1 image-notes"
          />
        </div>
        <section className="home-watch">
          <h2 className="home-title">Past Events</h2>
          <div className="home-gallery">
            <GalleryCard1
              image_src="https://i.ytimg.com/vi/Eq7TG8ggvqw/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFTyq4qpAw4IARUAAIhCGAFwAcABBg==&amp;rs=AOn4CLDQA0Wac2aiSp9z3ymGeXIXJSAAgg"
              rootClassName="rootClassName"
            ></GalleryCard1>
            <GalleryCard1
              heading="RCL 2022"
              image_src="https://i.ytimg.com/vi/UZExqwhyZIQ/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFTyq4qpAw4IARUAAIhCGAFwAcABBg==&amp;rs=AOn4CLCOKdieZFS9DKfI1dPc1VBPwm9j5A"
              rootClassName="rootClassName1"
            ></GalleryCard1>
            <GalleryCard1
              heading="Richmond's Favourites"
              image_src="https://i.ytimg.com/vi/wISGh9gumVg/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFTyq4qpAw4IARUAAIhCGAFwAcABBg==&amp;rs=AOn4CLB3ZyVShkn-uKbeqCaQnsPbwitueg"
              rootClassName="rootClassName2"
            ></GalleryCard1>
            <GalleryCard1
              heading="Big Debate"
              image_src="https://i.ytimg.com/vi/NvnXVlxWVss/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFTyq4qpAw4IARUAAIhCGAFwAcABBg==&amp;rs=AOn4CLDSz6GiD8MEK2Qiiut4MVY0-blo0A"
              rootClassName="rootClassName3"
            ></GalleryCard1>
            <GalleryCard1
              heading="Live Discussions"
              image_src="https://i.ytimg.com/vi/57gwhu9Wvjo/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFTyq4qpAw4IARUAAIhCGAFwAcABBg==&amp;rs=AOn4CLCxA2Xxya6LglaC6XJFGM7PIfXm1w"
              rootClassName="rootClassName4"
            ></GalleryCard1>
            <GalleryCard1
              heading="Lovers' Quarrel 2019"
              image_src="https://i.ytimg.com/vi/fqDx6d1IIEY/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFTyq4qpAw4IARUAAIhCGAFwAcABBg==&amp;rs=AOn4CLBjx109xtKUCMWnMv_h1PWKn58_Ng"
              rootClassName="rootClassName5"
            ></GalleryCard1>
          </div>
        </section>
      </section>
      <section className="home-watch1">
        <div className="home-content1">
          <span className="home-text23 team-section-title-2">Thinking Beyond the Trend...</span>
          <h1 className="home-text24">
            <span className="home-text25 team-section-title-1">Team Richmond Live</span>
            <br></br>
          </h1>
          <div className="home-video">
            <img
              alt="image"
              src="/playground_assets/play.svg"
              className="home-image2"
            />
            <video
              src="https://www.youtube.com/watch?v=7EcF_uzQX4k"
              poster="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/285592223_5269551916445658_471350638322264308_n.jpg?_nc_cat=107&amp;ccb=1-7&amp;_nc_sid=0debeb&amp;_nc_ohc=cJDV_yeJuQ4AX9IDI3G&amp;_nc_ht=scontent.fcmb2-2.fna&amp;oh=00_AfArDYQHvYSMjEswZ8tmBbn32ZV60ZKn16tzFSVhhzw0PQ&amp;oe=6412F6F0"
              controls
              className="home-video1"
            ></video>
          </div>
        </div>
        <footer className="home-footer">
          <span className="home-copyright">
            {' '}
            © {getCurrentYear()} Richmond Live. All Rights Reserved.    |    Designed and
            developed by Digital Operations Team.
          </span>
        </footer>
      </section>
      <div>
        <DangerousHTML
          html={`<script>
    ScrollReveal().reveal('.speaker');
    ScrollReveal().reveal('.image-notes');
</script>`}
        ></DangerousHTML>
      </div>
      <div>
        <DangerousHTML
          html={`<style>
#scrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#scrollbar::-webkit-scrollbar
{
	height: 12px;
	background-color: #F5F5F5;
	border-radius: 10px;
}

#scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
</style>`}
        ></DangerousHTML>
      </div>
    </div>
  )
}

export default Home
